@import "../../../../variables.scss";

.shuffling
{
	padding: $padding-large;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.shuffleButtons
{
    display: flex;
    justify-content: center;
}


.shuffleButtons button
{
	font-size: 0.75em;
    border-radius: 8px;
    padding: 8px 16px 8px 16px;
    margin: $padding-small;
	background: $black;
    color: $white;
}

.shuffleButtons button:hover:enabled,
.shuffleButtons button:active:enabled
{
    background: $orange;
    color: $black;
}

.shuffleButtons button:disabled
{
    background: rgba(195,36,57,0.2);
    cursor: default;
}
