@import "../../variables.scss";


.cardCount
{
    font-size: 0.75em;
    color: $black;
    margin: 0px;
    padding: 0px;
}
