.avatar
{
    display: inline-block;
    background-size: cover;
}

.avatarClickable
{
    cursor: pointer;
}

.avatar1
{
	background-image: url('img/avatar1.svg');
}

.avatar2
{
	background-image: url('img/avatar2.svg');
}

.avatar3
{
	background-image: url('img/avatar3.svg');
}

.avatar4
{
	background-image: url('img/avatar4.svg');
}

.avatar5
{
	background-image: url('img/avatar5.svg');
}

.avatar6
{
	background-image: url('img/avatar6.svg');
}

.avatar7
{
	background-image: url('img/avatar7.svg');
}

.avatar8
{
	background-image: url('img/avatar8.svg');
}

.avatar9
{
	background-image: url('img/avatar9.svg');
}

.avatar10
{
	background-image: url('img/avatar10.svg');
}

.avatar11
{
	background-image: url('img/avatar11.svg');
}

.avatar12
{
	background-image: url('img/avatar12.svg');
}
