@import "../../../../variables.scss";

.players
{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

@media only screen and ( min-width: $mobile-breakpoint )
{
    .players
    {
        width: auto;
        flex-direction: column;
    }
}
