@import "../../../../../variables.scss";

.player
{
    margin: 0px $padding-small;
    padding: $padding-tiny;
    color: $black;
}

.isCurrentPlayer
{
    text-decoration: underline;
}

.player p
{
    margin: 0px;
    padding: 0px;
}
