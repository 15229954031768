@import "../../../../../variables.scss";

.stack
{
    display: inline-block;
    padding: $padding-tiny;
}

.stackCards
{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.stackCard:first-child
{
    margin-top: 0px;
    overflow: visible;
}

.stackCard
{
    margin-top: -15px;
    overflow: hidden;
}
