@import "../../variables.scss";


.card
{
    display: inline-block;
    background-size: contain;
}

.cardClickable
{
    
    cursor: pointer;
}

.turned
{
    background-image: url('img/back.png');
}

.joker-spades
{
	background-image: url('img/joker-spades.svg');
}

.king-spades
{
	background-image: url('img/king-spades.svg');
}

.queen-spades
{
	background-image: url('img/queen-spades.svg');
}

.jack-spades
{
	background-image: url('img/jack-spades.svg');
}

.ten-spades
{
	background-image: url('img/ten-spades.svg');
}

.nine-spades
{
	background-image: url('img/nine-spades.svg');
}

.eight-spades
{
	background-image: url('img/eight-spades.svg');
}

.seven-spades
{
	background-image: url('img/seven-spades.svg');
}

.six-spades
{
	background-image: url('img/six-spades.svg');
}

.five-spades
{
	background-image: url('img/five-spades.svg');
}

.four-spades
{
	background-image: url('img/four-spades.svg');
}

.three-spades
{
	background-image: url('img/three-spades.svg');
}

.two-spades
{
	background-image: url('img/two-spades.svg');
}

.ace-spades
{
	background-image: url('img/ace-spades.svg');
}

.joker-diamonds
{
	background-image: url('img/joker-diamonds.svg');
}

.king-diamonds
{
	background-image: url('img/king-diamonds.svg');
}

.queen-diamonds
{
	background-image: url('img/queen-diamonds.svg');
}

.jack-diamonds
{
	background-image: url('img/jack-diamonds.svg');
}

.ten-diamonds
{
	background-image: url('img/ten-diamonds.svg');
}

.nine-diamonds
{
	background-image: url('img/nine-diamonds.svg');
}

.eight-diamonds
{
	background-image: url('img/eight-diamonds.svg');
}

.seven-diamonds
{
	background-image: url('img/seven-diamonds.svg');
}

.six-diamonds
{
	background-image: url('img/six-diamonds.svg');
}

.five-diamonds
{
	background-image: url('img/five-diamonds.svg');
}

.four-diamonds
{
	background-image: url('img/four-diamonds.svg');
}

.three-diamonds
{
	background-image: url('img/three-diamonds.svg');
}

.two-diamonds
{
	background-image: url('img/two-diamonds.svg');
}

.ace-diamonds
{
	background-image: url('img/ace-diamonds.svg');
}

.joker-hearts
{
	background-image: url('img/joker-hearts.svg');
}

.king-hearts
{
	background-image: url('img/king-hearts.svg');
}

.queen-hearts
{
	background-image: url('img/queen-hearts.svg');
}

.jack-hearts
{
	background-image: url('img/jack-hearts.svg');
}

.ten-hearts
{
	background-image: url('img/ten-hearts.svg');
}

.nine-hearts
{
	background-image: url('img/nine-hearts.svg');
}

.eight-hearts
{
	background-image: url('img/eight-hearts.svg');
}

.seven-hearts
{
	background-image: url('img/seven-hearts.svg');
}

.six-hearts
{
	background-image: url('img/six-hearts.svg');
}

.five-hearts
{
	background-image: url('img/five-hearts.svg');
}

.four-hearts
{
	background-image: url('img/four-hearts.svg');
}

.three-hearts
{
	background-image: url('img/three-hearts.svg');
}

.two-hearts
{
	background-image: url('img/two-hearts.svg');
}

.ace-hearts
{
	background-image: url('img/ace-hearts.svg');
}

.joker-clubs
{
	background-image: url('img/joker-clubs.svg');
}

.king-clubs
{
	background-image: url('img/king-clubs.svg');
}

.queen-clubs
{
	background-image: url('img/queen-clubs.svg');
}

.jack-clubs
{
	background-image: url('img/jack-clubs.svg');
}

.ten-clubs
{
	background-image: url('img/ten-clubs.svg');
}

.nine-clubs
{
	background-image: url('img/nine-clubs.svg');
}

.eight-clubs
{
	background-image: url('img/eight-clubs.svg');
}

.seven-clubs
{
	background-image: url('img/seven-clubs.svg');
}

.six-clubs
{
	background-image: url('img/six-clubs.svg');
}

.five-clubs
{
	background-image: url('img/five-clubs.svg');
}

.four-clubs
{
	background-image: url('img/four-clubs.svg');
}

.three-clubs
{
	background-image: url('img/three-clubs.svg');
}

.two-clubs
{
	background-image: url('img/two-clubs.svg');
}

.ace-clubs
{
	background-image: url('img/ace-clubs.svg');
}


.tiny-joker-spades
{
	background-image: url('img/tiny-joker-spades.svg');
}

.tiny-king-spades
{
	background-image: url('img/tiny-king-spades.svg');
}

.tiny-queen-spades
{
	background-image: url('img/tiny-queen-spades.svg');
}

.tiny-jack-spades
{
	background-image: url('img/tiny-jack-spades.svg');
}

.tiny-ten-spades
{
	background-image: url('img/tiny-ten-spades.svg');
}

.tiny-nine-spades
{
	background-image: url('img/tiny-nine-spades.svg');
}

.tiny-eight-spades
{
	background-image: url('img/tiny-eight-spades.svg');
}

.tiny-seven-spades
{
	background-image: url('img/tiny-seven-spades.svg');
}

.tiny-six-spades
{
	background-image: url('img/tiny-six-spades.svg');
}

.tiny-five-spades
{
	background-image: url('img/tiny-five-spades.svg');
}

.tiny-four-spades
{
	background-image: url('img/tiny-four-spades.svg');
}

.tiny-three-spades
{
	background-image: url('img/tiny-three-spades.svg');
}

.tiny-two-spades
{
	background-image: url('img/tiny-two-spades.svg');
}

.tiny-ace-spades
{
	background-image: url('img/tiny-ace-spades.svg');
}

.tiny-joker-diamonds
{
	background-image: url('img/tiny-joker-diamonds.svg');
}

.tiny-king-diamonds
{
	background-image: url('img/tiny-king-diamonds.svg');
}

.tiny-queen-diamonds
{
	background-image: url('img/tiny-queen-diamonds.svg');
}

.tiny-jack-diamonds
{
	background-image: url('img/tiny-jack-diamonds.svg');
}

.tiny-ten-diamonds
{
	background-image: url('img/tiny-ten-diamonds.svg');
}

.tiny-nine-diamonds
{
	background-image: url('img/tiny-nine-diamonds.svg');
}

.tiny-eight-diamonds
{
	background-image: url('img/tiny-eight-diamonds.svg');
}

.tiny-seven-diamonds
{
	background-image: url('img/tiny-seven-diamonds.svg');
}

.tiny-six-diamonds
{
	background-image: url('img/tiny-six-diamonds.svg');
}

.tiny-five-diamonds
{
	background-image: url('img/tiny-five-diamonds.svg');
}

.tiny-four-diamonds
{
	background-image: url('img/tiny-four-diamonds.svg');
}

.tiny-three-diamonds
{
	background-image: url('img/tiny-three-diamonds.svg');
}

.tiny-two-diamonds
{
	background-image: url('img/tiny-two-diamonds.svg');
}

.tiny-ace-diamonds
{
	background-image: url('img/tiny-ace-diamonds.svg');
}

.tiny-joker-hearts
{
	background-image: url('img/tiny-joker-hearts.svg');
}

.tiny-king-hearts
{
	background-image: url('img/tiny-king-hearts.svg');
}

.tiny-queen-hearts
{
	background-image: url('img/tiny-queen-hearts.svg');
}

.tiny-jack-hearts
{
	background-image: url('img/tiny-jack-hearts.svg');
}

.tiny-ten-hearts
{
	background-image: url('img/tiny-ten-hearts.svg');
}

.tiny-nine-hearts
{
	background-image: url('img/tiny-nine-hearts.svg');
}

.tiny-eight-hearts
{
	background-image: url('img/tiny-eight-hearts.svg');
}

.tiny-seven-hearts
{
	background-image: url('img/tiny-seven-hearts.svg');
}

.tiny-six-hearts
{
	background-image: url('img/tiny-six-hearts.svg');
}

.tiny-five-hearts
{
	background-image: url('img/tiny-five-hearts.svg');
}

.tiny-four-hearts
{
	background-image: url('img/tiny-four-hearts.svg');
}

.tiny-three-hearts
{
	background-image: url('img/tiny-three-hearts.svg');
}

.tiny-two-hearts
{
	background-image: url('img/tiny-two-hearts.svg');
}

.tiny-ace-hearts
{
	background-image: url('img/tiny-ace-hearts.svg');
}

.tiny-joker-clubs
{
	background-image: url('img/tiny-joker-clubs.svg');
}

.tiny-king-clubs
{
	background-image: url('img/tiny-king-clubs.svg');
}

.tiny-queen-clubs
{
	background-image: url('img/tiny-queen-clubs.svg');
}

.tiny-jack-clubs
{
	background-image: url('img/tiny-jack-clubs.svg');
}

.tiny-ten-clubs
{
	background-image: url('img/tiny-ten-clubs.svg');
}

.tiny-nine-clubs
{
	background-image: url('img/tiny-nine-clubs.svg');
}

.tiny-eight-clubs
{
	background-image: url('img/tiny-eight-clubs.svg');
}

.tiny-seven-clubs
{
	background-image: url('img/tiny-seven-clubs.svg');
}

.tiny-six-clubs
{
	background-image: url('img/tiny-six-clubs.svg');
}

.tiny-five-clubs
{
	background-image: url('img/tiny-five-clubs.svg');
}

.tiny-four-clubs
{
	background-image: url('img/tiny-four-clubs.svg');
}

.tiny-three-clubs
{
	background-image: url('img/tiny-three-clubs.svg');
}

.tiny-two-clubs
{
	background-image: url('img/tiny-two-clubs.svg');
}

.tiny-ace-clubs
{
	background-image: url('img/tiny-ace-clubs.svg');
}
