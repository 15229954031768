@import "./variables.scss";

body
{
    margin: 0;
    padding: 0;
    background: $teal;
    font-family: 'Baloo Chettan 2', sans-serif;
    color: $white;
    text-align: center;
}

h1
{
    font-size: $fontLarge;
    padding: $padding-small;
    margin: 0px;
}

h2
{
    font-size: $fontMedium;
    padding: 0px 0px $padding-small 0px;
    margin: 0px;
}

p
{
    padding: 0px 0px $padding-small 0px;
    margin: 0px;
    text-align: center;
}

div
{
    text-align: center;
}

table
{
    text-align: left;
    margin: 0 auto;
    padding-bottom: $padding-large;
}

table thead
{
    font-weight: bold;
}

table td
{
    padding: $padding-tiny;
}

input
{
    font-size: $fontMedium;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
    border: 2px solid $white;
    background-color: $white;
    width: 100%;
    box-sizing: border-box;
}

input:hover,
input:focus,
input:active
{
    border: 2px solid $orange;
}

.buttonsDiv
{
    padding: $padding-large;
}

.buttonsDiv button
{
    margin: 0px $padding-tiny;
    min-width: 150px;
}

button
{
    font-size: $fontSmall;
    border: none;
    border-radius: 16px;
    padding: 16px 24px 16px 24px;
    background: $white;
    color: $black;
}

button:disabled
{
    background: rgba(195,36,57,0.2);
}

button:hover:enabled,
button:focus:enabled,
button:active:enabled
{
    cursor: pointer;
    border: none;
    background: $orange;
    color: $black;
}

.smallOrangeButton
{
    background: $orange;
    padding: 6px 12px 6px 12px;
}

.smallOrangeButton:hover,
.smallOrangeButton:focus,
.smallOrangeButton:active
{
    background: $red;
}

a
{
    font-weight: bold;
    color: $white;
}

a:hover,
a:focus,
a:active
{
    cursor: pointer;
    color: $orange;
    text-decoration: underline;
}

.error-text
{
    color: $orange;
    font-size: $fontMedium;
}
