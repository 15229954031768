@import "../../../../variables.scss";

.sit
{
    font-size: $fontSmall;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: 10px;
    box-sizing: border-box;
    border: none;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px;
    background: $white;
    color: $black;
}

.sit p
{
    margin: 0px;
    padding: 0px;
    fontSize: 1em;
}

.north
{
    position: absolute;
    top: 0px;
    left: 90px;
}

.south
{
    position: absolute;
    bottom: 0px;
    left: 90px;
}

.west
{
    position: absolute;
    top: 90px;
    left: 0px;
}

.east
{
    position: absolute;
    top: 90px;
    right: 0px;
}

@media only screen and ( min-width: $mobile-breakpoint )
{
    .sit
    {
        width: 150px;
        height: 150px;
        border-radius: 75px;
        margin: 20px;
    }

    .north
    {
        left: 130px;
    }

    .south
    {
        left: 130px;
    }

    .west
    {
        top: 130px;
    }

    .east
    {
        top: 130px;
    }
}
