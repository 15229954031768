@import "../../../../variables.scss";

.hand
{
    display: flex;
    flex-direction: column;
}

.hand button
{
    font-size: 0.75em;
    border-radius: 8px;
    padding: 8px 16px 8px 16px;
    background: $black;
    color: $white;
}

.hand button:hover:enabled,
.hand button:focus:enabled,
.hand button:active:enabled
{
    background: $orange;
    color: $black;
}

.hand button:disabled
{
    background: rgba(195,36,57,0.2);
    cursor: default;
}

.cards
{
    display: block;
    width: 100%;
}

.playerName
{
    display: block;
}

.cardContainer
{
    display: flex;
    justify-content: center;
    padding: 0px;
    margin: 0px;
}

.cardWrapper
{
    overflow: hidden;
    position: relative;
}

.cardSelected
{
    transform: translateY(-20px);
}

.cardWrapper
{
    margin-left: -30px;
}

.cardWrapper:first-child
{
    margin-left: 0px;
    overflow: visible;
}

.cardWrapper div
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.handButtons
{
    display: flex;
    justify-content: center;
}

.handButtons button
{
    margin: $padding-small;
}
