@import "../../variables.scss";

#dialog-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
}

#blind {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.75);
}

#dialog {
  padding: $padding-large;
  position: relative;
  width: 100%;
  width: 300px;
  background: $teal;
  top: 50px;
  margin: auto;
  z-index: 10000;
}

.buttons {
  padding-top: $padding-large;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-around;
}

.buttons button {
    min-width: 125px;
}

@media only screen and (min-width: $mobile-breakpoint) {
  #dialog {
    width: 375px;
  }
}
