@import "../../../../variables.scss";

.stacks
{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: $padding-small;
}

.stacks p
{
    margin: 0px;
    padding: 0px;
}
