@import "../../../variables.scss";


.full
{
    background: $white;
    overflow-x: hidden;
    left: 0px;
    top: 0px;
    right: 0px;
}

.tableCards
{
    width: 100%;
    display: inline-block;
    display: flex;
    flex-direction: column;
}

.tablePlayers
{
    width: 100%;
    display: inline-block;
}

.gameTable
{
    display: flex;
    flex-direction: column;
}

@media only screen and ( min-width: $mobile-breakpoint )
{
    .tableCards
    {
        width: 100%;
    }

    .tablePlayers
    {
        float: left;
        width: auto;
    }

    .gameTable
    {
        flex-direction: row;
    }
}
