@import "../../variables.scss";

.target
{
    box-sizing: border-box;
    padding: $padding-tiny;
    margin: 0px $padding-tiny;
    border: 2px solid rgba(0,0,0,0);
    display: inline-block;
}

.targetActive
{
    border: 2px solid $orange;
}
