@import "../../../variables.scss";

.table
{
    background: $black;
    width: 300px;
    height: 300px;
    border-radius: 225px;
    margin: auto auto;
    position: relative;
}

@media only screen and ( min-width: $mobile-breakpoint )
{
    .table
    {
        width: 450px;
        height: 450px;
        border-radius: 225px;
    }
}
