@import "../../../../variables.scss";

.headerContainer
{
    background: $teal;
}

.state
{
	font-size: 0.5em;
	padding: 0 $padding-large;
}

.headerContainer p
{
    margin: 0px;
    padding: $padding-tiny $padding-medium;
}

.header
{
    width: 100%;
    background-color: $black;
    display: flex;
    justify-content: space-between;
}

.notices
{
    background: $teal;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.score
{

}

.playerscore
{
    text-decoration: underline;
}

.header h1
{
    text-align: left;
    display: inline-block;
    font-size: $fontSmall;
    padding: $padding-small ;
    margin: 0px;
}

.header h2
{
    text-align: right;
    display: inline-block;
    font-size: $fontSmall;
    padding: $padding-small ;
    margin: 0px;
}

@media ( min-width: 740px )
{
    .header h1
    {
        font-size: $fontMedium;
    }

    .header h2
    {
        font-size: $fontMedium;
    }
}

.message
{

}

.oldMessage.hide
{
    transform: translateX(-2000px);
}

.oldMessage
{
    opacity: 1;
    animation: oldAnimation 2s ease-in-out forwards;
}

.newMessage
{
    opacity: 0;
    animation: newAnimation 2s ease-in-out forwards;
}

@keyframes newAnimation
{
    from
    {
        opacity: 0;
        transform: translateX(2000px);
    }

    to
    {
        opacity: 1;
        transform : translateX(0);
    }
}

@keyframes oldAnimation
{
    from
    {
        opacity: 1;
        transform: translateX(0px);
    }

    to
    {
        opacity: 0;
        transform : translateX(-1000);
    }
}
