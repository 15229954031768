@import "../../../../variables.scss";

.stackDiscard
{
    display: flex;
    justify-content: center;
}

.draw,
.discard
{
    padding: $margin-small;
    box-sizing: border-box;
    display: block;
}
