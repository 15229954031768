$white: #D2DDD8;
$black: #1D1D1D;
$navy: #122034;
$teal: #4B666E;
$orange: #DC7928;
$red: #C32439;

$fontLarge: 2em;
$fontMedium: 1.5em;
$fontSmall: 1em;

$mobile-breakpoint: 720px;

$padding-large: 32px;
$padding-medium: 24px;
$padding-small: 16px;
$padding-tiny: 8px;
$padding-tiny: 2px;

$margin-large: 64px;
$margin-small: 16px;
$margin-tiny: 8px;


$desktop-content-max-width: 1200px;

$button-top-margin: 16px;
